.swiper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: 40%;
}

.swiper-scrollbar {
  position: relative;
  width: 100px !important;
  bottom: 12px !important;
  margin-left: calc(50% - 50px);
}
